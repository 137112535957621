@import "scss/base.scss";

.addNewOrderPageInfo {
  height: 80vh;
  background-color: $color-background-off-white;
}

.bar {
  background-color: $color-greenStory-grey;
  width: 100%;
  height: 5px;
}

.barContainer {
  height: 0.5vh;
}


.addNewOrderForm {
  text-align: center;
}