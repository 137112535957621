.container {
  height:50px;
  .deleteBtn {
    width: 20px;
  }

  .date {
    width: 170px;
  }

  .orderNumber {
    width: 150px;
    text-align: center;
  }

  .amount {
    width: 150px;
    text-align: right;
   
    &.creditMemo {
      color: green;
    }
    &.order {
      color: red
    }
  }

 

 

}