@import "scss/base.scss";

.labels {
  width: 5rem;
  display: inline-block;
}

.field {
  text-align: left;
}

.textField {
  min-width: 300px;
}

.fieldError {
  color: $color-alert;
  width: 12rem;
  display: inline-block;
}

.modal {
  min-width: 200px;
  min-height: 200px;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%,50%);
  position: absolute;
  background-color: #fff;
  border: 8px solid black;
  border-radius: 25px;
  padding: 25px;
  pointer-events: auto;

  .button {
    position: absolute;
    height:30px;
    bottom: 0px;

  }
}

.buttonSection{
  text-align: center;
  padding-top: 15px;
}

.submitButton{
  margin-left: 35px;
}

.closeButton{
  margin-right: 35px;
}

.row {
  display:flex;
  padding-top: 15px;
  justify-content: space-between;
  padding-bottom: 10px;

}
.title {
  font-size: 25px;
  text-decoration: underline;
}
.fields {
  display:flex;
  line-height: 100%;
  width: 160px;
  height: 100%;

}

.datePick {
  background-color: white;
  align-items: center;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
  height: 100%;

}


