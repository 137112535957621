@import "scss/base.scss";


.modal {
  min-width: 200px;
  min-height: 200px;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%,50%);
  position: absolute;
  background-color: #fff;
  border: 8px solid black;
  border-radius: 25px;
  padding: 25px;
  pointer-events: auto;


  .title {
    text-align: center;
    font-size: 25px;
    border-bottom: 3px solid black;
  }

  .message {
    padding-top: 20px;
    text-align: center;

  }

  .button {
    padding-top: 100px;
    height:30px;
    text-align: center;
  }
}

