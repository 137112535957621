@import "scss/base.scss";

.paginationContainer {
  margin: 15px;
}

.pageTile, .currentPageTile {
  width: 15px;
  height: 15px;
  padding: 15px;
  border-radius: 100px;
  list-style: none;
  cursor:pointer;

  &:hover {
    background-color: black;
    color: white;
    }
}

.currentPageTile {
  color: $white;
  background: $gojava-purple;
}


.ellipse{
  background-color: $color-background-off-white;
  list-style-type: none;  
  font-size: 34px;
  margin-bottom: 12px;
  cursor:pointer;
}
 
.pagination{
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
}