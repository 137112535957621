@import "scss/base.scss";

.bar {
  background-color: $color-greenStory-grey;
  width: 100%;
  height: 5px;
}

.barContainer{
  height: 0.5vh;
}
