//shared
//-----------------------------
@mixin icon-helper(){
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    max-width: 100%;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    outline: none;
  }
  
  //Images
  //-----------------------------
  //LOGO
  .logo--pantree {
    @include icon-helper;
    background-image: url('../assets/images/pantree_final.png');
  }
  //PNG IMAPCTS
  .i--impact--cntower {
    @include icon-helper;
    background-image: url('../assets/images/impacts/gojava_cntower.png');
  }
  .i--impact--coffeepods {
    @include icon-helper;
    background-image: url('../assets/images/impacts/gojava_coffee_pods.png');
  }
  .i--impact--ewaste {
    @include icon-helper;
    background-image: url('../assets/images/impacts/gojava_ewaste.png');
  }
  .i--impact--foilpackaging {
    @include icon-helper;
    background-image: url('../assets/images/impacts/gojava_foil_packaging.png');
  }
  .i--impact--organicwaste {
    @include icon-helper;
    background-image: url('../assets/images/impacts/gojava_organic_waste.png');
  }
  .i--impact--otherwaste {
    @include icon-helper;
    background-image: url('../assets/images/impacts/gojava_other_waste.png');
  }
  .i--impact--papercups {
    @include icon-helper;
    background-image: url('../assets/images/impacts/gojava_paper_cups.png');
  }
  .i--impact--trees {
    @include icon-helper;
    background-image: url('../assets/images/impacts/gojava_trees.png');
  }
  .i--impact--waste {
    @include icon-helper;
    background-image: url('../assets/images/impacts/gojava_waste.png');
  }
  .i--impact--wastebin {
    @include icon-helper;
    background-image: url('../assets/images/impacts/gojava_waste_bin.png');
  }
