/*
Try to utilize variables as much as possible.  It's one of the best things about a CSS preprocessor like Sass and it makes updates much easier
*/

//theme defaults
$color-primary: #787878;
$color-secondary: #b1df95;
$color-alert: #ff0000;
$color-success: #b1df95;
$color-greenStory-grey: #333;
$color-greenStory-green: #4fd621;
$color-background-off-white: #F5F5F5;


//  colour background
$color-background: #ffffff;
$color-background-secondary: #551d00;
$color-highlight: #b1df95;
$color-box-shadow: #888888;
$color-background-footer: #b1df95;

// colour text
$color-text: #787878;
$color-text-secondary: #ffffff;
$color-text-link: #ffc7aa;
$color-text-disabled: #787878;

$color-border: #a3a3a3;

// buttons
$color-button-background: $color-background-secondary;
$color-button-text: $color-secondary;

$color-button-background-disabled: #c7c7c7;

// borders:
$color-border: $color-primary;

// modals
$color-modal-background: #d48e6a;
$color-modal-text: #ffffff;

// header
$color-header-border: #b1df95;
$color-menu-text: #551d00;
$color-menu-background: #ffffff;

//flashtext
$color-flash-from: #d48e6a;

// Brand Colours
$white: #FFFFFF;
$button-color: #25282b;
$button-secondary-color: #F6AD00;
$border-color: #D8D8D8;
$medium-grey: #CECECE;

$gojava-brown: #d8a465;
$gojava-purple: #9011ff