@import "scss/base.scss";

.datePick {
    background-color: white;
    align-items: center;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid black;
    height: 100%;
    width: 160px;
  }
  