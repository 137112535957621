

.modal {
  min-width: 200px;
  min-height: 200px;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%,50%);
  position: absolute;
  background-color: #fff;
  border: 8px solid black;
  border-radius: 25px;
  padding: 25px;
  pointer-events: auto;

  .title {
    text-align: center;
    font-size: 25px;
    border-bottom: 3px solid black;
  }

  .message {
    padding-top: 20px;
    text-align: center;

  }

  .button {
    height:30px;
    bottom: 0px;
  }

  .confirmationOptions{
    display: flex;
    text-align: center;
    padding-top: 90px;
    width: 320px;
  }

  .cancelButton{
    margin-left: 30px;
    margin-right: 30px;

  }

  .confirmButton{
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .baseButton {
    width: 100%;
    height: 40px;
    color: white;
    background-color: black;
    border-radius: 100px;
    padding: 10px 25px;
    cursor: pointer;
    position: relative;
    outline: none;
  }
  
}
