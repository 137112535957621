.downloadButton {
  width: 120px;
  color: white;
  background-color: black;
  border-radius: 100px;
  padding: 10px 25px;
  cursor: pointer;
  position: relative;
  outline: none;

  .a {
    color: #FFFFFF;
}
}