.horizontalNav {
    display: flex;
    width: 100%;
}

.navSection {
    flex: 1;
    width: 200px;
    max-width: 200px;
    border: solid 1px #d5d5d5;
    padding: 15px;
}

.pageSection {
    flex: 1;
    height: 100vh;
    padding: 30px 15px;
}