@import "../scss/base.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: $color-background;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $color-text;
}

.App-link {
  color: $color-text-link;
}


html, body {
  height: 100%;
}

body {
  display: flex;
  background: $color-background-off-white;
  background-repeat: none;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}