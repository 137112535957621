@import "scss/base.scss";

.labels {
  width: 12rem;
  display: inline-block;
}

.fieldError {
  color: $color-alert;
  width: 12rem;
  display: inline-block;
}

.modal {
  min-width: 200px;
  min-height: 100px;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
  position: absolute;
  background-color: #fff;
  border: 8px solid black;
  border-radius: 25px;
  padding: 25px;
  pointer-events: auto;

  .button {
    position: absolute;
    height: 30px;
    bottom: 0px;
  }
}

.buttonSection {
  text-align: center;
  padding-top: 15px;
}

.submitButton {
  margin-right: 35px;
}

.closeButton {
  margin-left: 35px;
}

.startDate {
  text-align: left;
  display: flex;
}

.dateLabel {
  padding-top: 10px;
  width: 150px;
}

.dateFieldContainer {
  margin-left: 33px;
  width: 160px;
}

.treesPerQuantity {
  margin-left: 85px;
  width: 160px;
}

.dateField {
  text-align: center;
  height: 30px;
  width: 142px;
  border: 1px solid black;
  margin-top: 5px;
  margin-left: 50px;
  padding-left: 5px;
  padding-right: 15px;
}

.productRow {
  display: flex;
}

.linkIcons {
  margin-top: 5px;
  margin-left: 15px;
}

.prodName {
  margin-top: 5px;
  text-align: left;
}
