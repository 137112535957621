
.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 3px;
  justify-content: space-evenly;
}

.connectBtn {
  background-color: blue;
}

.connected{
  color: green;
}
.disconnected{
  color: rgb(0, 0, 0);
}

.deleteSection{
  width: 50px;
}
.textField {

  display: flex;
}
.field {
  width: 250;
}
.connection {
  width: 100px; 
  display: flex;
  padding-left: 15px;
  padding-top: 5px;

  .icon {
    width: 40px;
  }
}

.delete {
  display: flex;
  padding-top: 5px;
  width: 100%;
}

.icon{
  padding-top: 5px;

}