@import "scss/base.scss";

.fields {
  display:flex;
  padding-right: 50px;
  padding-top: 15px;
}

.searchIcons{
  padding-top: 10px;
}

.missingFieldError {
  color: red;
}

.createSuccess {
  color: green;
}


.datePick {
  background-color: white;
  align-items: center;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
  height: 100%;
  width: 160px;
}

.orderButton {
  margin-top: 30px;
  border: none;
  transition: all 0.3s ease;
  overflow: hidden;
}

.orderButton:hover {
  background: $color-greenStory-green;
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
  color: rgb(255, 255, 255);
}

.tableFilters{
  text-align: left;
}


.plasticsChange {
  display: flex;
  flex-direction: row;
  margin-left: auto;

}