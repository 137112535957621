@import "scss/base.scss";

.labels {
  width: 5rem;
  display: inline-block;
}

.field {
  margin-right: $margin-small;
}

.signInButtons{
  text-align: center;
  display: flex;
  padding-top: 10px;
  padding-left: calc(52.6% - 150px);
  width:300px;
}

.forgotPasswordDiv{
  padding-top: 15px;
}

.forgotPasswordButton{
  border: hidden;
  background-color: inherit;
  color: grey;
  text-decoration: underline;
}