

.backGround{
    z-index:999;
    background-color: rgba(0,0,0,0.65);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  