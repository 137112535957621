@import "scss/base.scss";

.selectorBar{
  color: black;
}
.selectorRow{
  color: black;
}


.selectContainer {
  opacity: 100;
  width: 300px;
  height: $input-min-height;
  padding: $margin-tiniest;
  padding-left: $margin-tiny;
}

.clientDropdownSection{
  display: flex;
  text-align: center;
}

.icon{
  padding-top: 10px;
  margin-right: 10px;
}