@import "scss/base.scss";



.searchIcons{
  padding-top: 10px;
}

.createSuccess {
  color: green;
}

.total {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-right: 54px;
  .title {  
    margin-right: 10px;
    font-weight: bold;
  }
  .credit {
    color: green;
  }
  .owing {
    color: red;
  }
  span {
    color: black;
  }
}

.buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.header {
  text-align: center;
}

.toggle {
  margin-left: 20px;
  margin-right: 20px;
}