@import "scss/base.scss";

.headerSection {
  display: flex;
  padding-top: 15px;
  margin-bottom: 10px;
  justify-content: space-around;
}

.datePickDiv {
  display: flex;
  align-items: left;
  width: 400px;
  padding-top: 10px;
}

.dateFieldContainer{
  text-align: center;
  margin-left: 25px;
}

.dateField{
  padding-top: 10px;
  width:300px;
  height: 25px;
  text-align: center;
}

.selectDiv {
  display: flex;
  padding-top: 10px;
  text-align: center;
}

.selectContainer{
  margin-left: 25px;
  width: 310px;
}

.ClientSelect{
  display: flex;
  padding-top: 10px;
  text-align: center;
  z-index: 999;
}

.filtersButton {
  width: 100%;
  height: 100%;
  color: white;
  background-color: black;
  border-radius: 100px;
  padding: 10px 25px;
  cursor: pointer;
  outline: none;
}

.setDateButtonSection {
  padding-right: 15px;
}

.bar {
  background-color: $color-greenStory-grey;
  width: 100%;
  height: 5px;
}

.barContainer {
  height: 0.5vh;
}

.SpendTracker {
  overflow: hidden;
}

.fieldLabel {
  padding: 10px;
  min-width: 110px;
  text-align: left;
}

.loadingScreen {
  position: relative;
  z-index: 999;
  background-color: rgba(95, 95, 95, 0.40);
  pointer-events: none;
}


@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  position: absolute; 
  z-index: 999;
  inset: 0;
  margin: auto;

  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.filterMenu{
  position: absolute;
  width: 500px;
  padding: 10px;
  background-color: #ffffff;
  border-style: solid;
  border-color: #383636;
  border-radius: 5px;
}

.buttonSection{
  margin-top: 15px;
  text-align: center;
}

.submitButton{
  margin-right: 10px;
}

.clearButton{
  margin-left: 10px;
}

.noData{
  font-size: 30px;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 200px;
  height: 100px;
  padding-left: 170px;
}

.clientTitle {
  display: flex;
  font-size: 40px;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
}

.filterSummary {
  padding-left: 20px;
  .filterItem {
  span {
    font-weight: bold;
    font-size: 20px;
  }
  padding-bottom: 2px
  }
}

.downloadCSV{
  padding-top: 20px;
  padding-left: 100px;
}

