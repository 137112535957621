@import "scss/base.scss";

.label {
  margin-right: $margin-medium;
  text-align: left;

}

.required,
.alert {
  color: $color-alert;
  font-size: 12px;
  text-align: left;
}

.field {
  height: 30px;
  border: 1px solid black;
  margin-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

