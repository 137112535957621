@import "scss/base.scss";
.datePickDiv {
  margin-left: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.timeButtons {
  padding: 15px;
  text-align: center;
}

.impactTimeButtons {
  appearance: none;
  margin-left: 15px;
  transition: all 0.3s ease;
  overflow: hidden;
  border: none;
  color: $white;
  background: $gojava-purple;
  padding: 10px 15px;
  border-radius: 100px;
  cursor: pointer;
}
