@import "scss/base.scss";

.labels {
  width: 12rem;
  display: inline-block;
}

.field {
  margin-right: $margin-small;
}

.tableButton {
  border: none;
  background-color: inherit;
  transition: all 0.3s ease;
  overflow: hidden;
}

.iconDiv {
  padding: 0;
  text-align: center;
}

.icons{
    size: 3rem;
}


.createUserButton{
  margin-right: 200px;
  margin-top: 5px;
  margin-bottom: 5px;
}


