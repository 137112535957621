@import "scss/base.scss";

.labels {
  width: 12rem;
  display: inline-block;
}

.modal {
  min-width: 200px;
  min-height: 100px;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%,50%);
  position: absolute;
  background-color: #fff;
  border: 8px solid black;
  border-radius: 25px;
  padding: 25px;
  pointer-events: auto;

  .button {
    position: absolute;
    height:30px;
    bottom: 0px;

  }
}

.addEmail {
  float: right;
}

.emails {
  margin-top: 15px;
  display: flex;
}

.emailTitle{
  padding-top: 10px;
}

.clientNameFiled{
  text-align: left;
}

.buttonSection{
  text-align: center;
  padding-top: 20px;
}

.nameField{
  display: flex;

}
.nameLabel{
  padding-top: 5px;
  padding-right: 48px;
}


.siteConfirmation{
  display: flex;
  width:50%;
  flex-direction: row;
  justify-content: space-between;
  .nameLabel{
    padding-top: 5px;
    padding-right: 10px;
    text-align: right;
  }
  .foodServiceBox{
    text-align: center;
  }
}

.pantreeBox{
  text-align: center;
  padding-left: 80px;
}
.siteBox:focus {
  outline: none;
  border-color: black;
}

.closeButton{
  margin-right: 35px;
}

.fieldError {
  color: $color-alert;
  width: 12rem;
  display: inline-block;
}

.saveCancelBtns {
  margin-top: 15px;
  display: flex;
}

