@import "scss/base.scss";

.labels {
  width: 12rem;
  display: inline-block;
}

.field {
  margin-right: $margin-small;
}

.headerSection {
  display: flex;
  padding-top: 15px;
  margin-bottom: 10px;
  justify-content: space-around;
}

.impactTimeButtons {
  appearance: none;
  margin-left: 15px;
  transition: all 0.3s ease;
  overflow: hidden;
  border: none;
  color: $white;
  background: $gojava-purple;
  padding: 10px 15px;
  border-radius: 100px;
  cursor: pointer;
}

.filterSelected {
  color: white;
  background-color: black;
}

.datePickDiv {
  margin-left: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.timeButtons {
  padding: 15px;
  text-align: center;
}

.iconDiv {
  height: 200px;
  width: 200px;
  margin: auto;
}

.impactTable {
  margin-top: 10px;

  td {
    text-align: center;
  }
}

.infoType {
  margin-top: 40px;
}

.clientName{
  text-align: center;
  font-size: 40px;
  padding: 15px;
}

.searching {
  padding-top: 10px;
  padding-left: 10px;
  text-align: center;
}

.info {
  font-size: 19px;
  height: 30px;
  line-height: 30px;
  font-weight: bold;

}

.combinedData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2px;

  span {
    margin-left: 5px;
  }
}
