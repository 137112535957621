@import "scss/base.scss";

.loadingContainer {
  min-width: $button-min-width;
  margin: auto;
}

.loading, .loading:before, .loading:after {
	border-radius: 50%;
	background-color: $white;
	width: $icon-size-tiny;
	height: $icon-size-tiny;
	transform-origin: center center;
	display: block;
}
.loading {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
	background-color: rgba($white,1);
	opacity: 1;
	-webkit-animation: loadingAlpha 1s infinite linear;
	animation: loadingAlpha 1s infinite linear;
}
.loading:before, .loading:after {
	content: '';
  opacity: 0.25;
  position: absolute;
}
.loading:before {
	left: 30px;
	top: 0px;
	-webkit-animation: loadingAlphaBefore 1s infinite linear;
	animation: loadingAlphaBefore 1s infinite linear;
}
.loading:after {
	left: -30px;
	top: 0px;
	-webkit-animation: loadingAlphaAfter 1s infinite linear;
	animation: loadingAlphaAfter 1s infinite linear;
}
@-webkit-keyframes loadingAlpha {
	0% { opacity: 1; }
	33% { opacity: 0.25; }
	66% { opacity: 0.25; }
	100% { opacity: 1; }
}
@keyframes loadingAlpha {
	0% { opacity: 1; }
	33% {  opacity: 0.25; }
	66% { opacity: 0.25; }
	100% {  opacity: 1; }
}
@-webkit-keyframes loadingAlphaBefore {
	0% { opacity: 0.25; }
	33% { opacity: 1; }
	66% { opacity: 0.25; }
}
@keyframes loadingAlphaBefore {
	0% { opacity: 0.25; }
	33% { opacity: 1; }
	66% { opacity: 0.25; }
}
@-webkit-keyframes loadingAlphaAfter {
	33% { opacity: 0.25; }
	66% { opacity: 1; }
	100% { opacity: 0.25; }
}
@keyframes loadingAlphaAfter {
	33% { opacity: 0.25; }
	66% { opacity: 1; }
	100% { opacity: 0.25; }
}

.button {
  appearance: none;
  border: none;
  box-shadow: none;
  width: fit-content;
  outline: none;
  text-align: center;
  margin: auto;
  min-height: $button-size-small;
  height: $button-size-small;
  line-height: $button-size-small;
  padding: 0 $padding-small;
  border-radius: $radius-large;
  cursor: pointer;
  width: $full;
  position: relative;
  
  a {
    font-size: $font-size-small;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.skinnyPrimary {
    background-color: $button-color;
    border-color: $button-color;
    color: $white;
    height: $button-height-small;
    line-height: $button-height-small;

    &:hover {
      background-color: rgba($button-color, 0.75);
      border-color: rgba($button-color, 0.75);
    }
  }

  &.skinnySecondary {
    background-color: $button-secondary-color;
    border-color: $button-secondary-color;
    color: $white;
    height: $button-height-small;
    &:hover {
      background-color: rgba($button-secondary-color, 0.75);
      border-color: rgba($button-secondary-color, 0.75);
    }
  }

  &.pathOption{
    background-color: $white;
    border: solid;
    border-width: $border-path-size ;
    border-color: $border-color;
    border-radius: $radius-tiniest;
    color: $button-secondary-color;
    height: 100px;
    width: $button-path-option-width;
    font-weight: bold;
  
  }

  &.primary {
    background-color: $button-color;
    border-color: $button-color;
    color: white;
    height: $button-height;
    line-height: $button-height;
    width: auto;

    span, a {
      color: white;
    }

    i {
      border-color: $white;
    }

    &:hover {
      background-color: rgba($button-color, 0.75);
      border-color: rgba($button-color, 0.75);
    }

    &:active {
      background-color: $button-color;
      border-color: $button-color;
    }

    &:disabled {
      background-color: $medium-grey;
      border-color:  $medium-grey;

      &:hover {
        background-color:  $medium-grey;
        border-color:  $medium-grey;
      }
    }
  }

  &.secondary {
    color: $color-primary;
    background-color: $button-secondary-color;
    border-color: $button-secondary-color;
    color: white;
    height: $button-height;
    line-height: $button-height;
    width: auto;
    display: block;
    
    span, a {
      color: white;
    }

    i {
      border-color: $white;
    }

    &:hover {
      background-color: rgba($button-secondary-color, 0.75);
      border-color: rgba($button-secondary-color, 0.75);
    }

    &:disabled {
      background-color: rgba($color-button-background-disabled, 0.5);
      border-color: rgba($color-button-background-disabled, 0.5);
      &:hover {
        background-color: rgba($color-button-background-disabled, 0.25);
        border-color: rgba($color-button-background-disabled, 0.25);
      }
    }
  }

  &.underline {
    text-decoration: underline;
    background: none;
    padding: 0px;
    height: $button-height;
    line-height: $button-height;
    width: fit-content;
    font-size: $font-size-mobile;

    &:hover {
      text-decoration: none;
    }
  }

  i {
    border-width: 0 2px 2px 0;
    padding: 4px;
  }

}

.linkButton {
  min-height: $button-height;
  height: $button-height;
  line-height: $button-height;
  display: block;
  text-decoration-line: none; 
  position: relative;

  &.primary {
    i, div {
      position: absolute;
      top: 0;
      bottom: 0;
      right: $padding-medium;
      margin: auto;
      width: $icon-size / 1.2;
      height: $icon-size / 1.2;
    }
  }


}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
  .button {
    height: $button-height;
    line-height: $button-height;

    &.underline {
      font-size: $font-size-smallText;
  
      &:hover {
        text-decoration: none;
      }
    }
  }
}