@import "scss/base.scss";

.homePage{
  background-color: $color-background-off-white;
  height: 80vh;
}

.bar{
  background-color: $color-greenStory-grey;
  width: 100%;
  height: 5px;
}

.barContainer{
  height: 0.5vh;
}
