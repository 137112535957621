@import "scss/base.scss";

.labels {
  width: 12rem;
  display: inline-block;
}

.field {
  margin-right: $margin-small;
}

.datePickDiv {
  display: flex;
  align-items: center;
  padding-right: 75px;
}

.selectClientDiv {
  display: flex;
}

.pageSelectionDiv {
  display: flex;
  display : block;
  align-items: right;
  padding-right: 10px;
}

.pageNum{
  text-align: right;
}

.currentPageNum{
  text-align: right;
  padding-top: 1vh;
}

.bar{
  background-color: $color-greenStory-grey;
  width: 100%;
  height: 5px;
}

.barContainer{
  height: 0.5vh;
}
.table {
  margin-top: 10px;
}

.tableColumn{
  text-align:center;
}

.impactButton {
  border: none;
  background-color: inherit;
  transition: all 0.3s ease;
  overflow: hidden;
}

.iconDiv {
  padding: 0;
  text-align: center;
}

.headerSection {
  display: flex;
  padding-top: 15px;
  margin-bottom: 10px;
  justify-content: space-around;
}

.searchBar {
  justify-content: left;
}

