@import "scss/base.scss";

.baseButton {
  width: 100%;
  height: 40px;
  color: white;
  background-color: black;
  border-radius: 100px;
  padding: 10px 15px;
  cursor: pointer;
  position: relative;
  outline: none;
}

.hoverOverButton {
  border: none;
  z-index: 1;

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    border-radius: 100px;
    color: $white;
    background-color: $gojava-purple;
  }
  &:hover {
    color: #fff;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
  &:active {
    top: 2px;
  }
}

.buttonContainer {
  padding: 10px;
}
.style1 {
  background-color: black;  
  color:white;
}

.style2 {
  background-color: white;
  border: 5px solid black;
  color: black;
}


