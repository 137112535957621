@import "scss/base.scss";

header {
  display: flex;
  flex-direction: column;
  background-color: $color-background-off-white;
}

.headerLogo {
  margin-bottom: 30px;
  height: 3rem;
  width: 200px;

  :global(.leaf) {
    fill: white;
  }
}
.nav {
  display: none;
  margin-left: auto;
  margin-right: $margin-medium;
  right: 0;
}

.userLog {
  background-color: $color-background-footer;
  color: black;
  border-color: $color-background-footer;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
    color: whitesmoke;
  }
}

.bottomSpacer {
  margin-top: auto;
}

