@import "scss/base.scss";

.labels {
  width: 12rem;
  display: inline-block;
}

.field {
  margin-right: $margin-small;
}

.datePickDiv {
  padding-left: 35%;
  padding-right: 35%;
  display: flex;
  align-items: center;
}

.clientReportFields{
  align-items: center;
}


.reportButtons{ 
  padding-left: 43%;
  padding-right: 40%;
  padding-top: 20px;
  display: flex;
  align-items: center;
}

.reportActionButtons {
  border: none;
  transition: all 0.3s ease;
  overflow: hidden;
}

.reportActionButtons:hover {
  background: $color-greenStory-green;
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
  color: rgb(255, 255, 255);
}


