@import "scss/base.scss";

.labels {
  width: 12rem;
  display: inline-block;
}

.fieldError {
  color: $color-alert;
  width: 12rem;
  display: inline-block;
}

.modal {
  min-width: 200px;
  min-height: 200px;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%,50%);
  position: absolute;
  background-color: #fff;
  border: 8px solid black;
  border-radius: 25px;
  padding: 25px;
  pointer-events: auto;

  .button {
    position: absolute;
    height:30px;
    bottom: 0px;

  }
}

.buttonSection{
  text-align: center;
  padding-top: 15px;
}

.submitButton{
  margin-left: 35px;
}

.closeButton{
  margin-right: 35px;
}


