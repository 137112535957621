@import "./base/colors.scss";
@import "./base/sizes.scss";
@import "./base/grid.scss";
@import "./base/media-queries.scss";
@import "./base/anim.scss";
@import "./icons.scss";

html,
body,
#root {
  width: 100%;
  height: 100%;
}

div,
p,
a,
span,
input,
button {
  font-family: NanamiLight;
}

h1 {
  font-size: 35px;
  text-align: left;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  margin: 0;
}

.hide {
  display: none;
}

.greyed-out {
  color: $color-text-disabled !important;
}

// Table Styles
.tableFilters {
  min-height: 50px;
  display: flex;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: solid 1px #d5d5d5;
  text-align: left;
  padding: 12px;
}

th {
  background-color: black;
  color: white;
}

.striped:hover {
  background-color: #e4f7fa;
}

.striped:last-child {
  border-collapse: separate;
}

.striped:nth-child(even) {
  background-color: #f9f9f9;

  &:hover {
    background-color: #e4f7fa;
  }
}

// Filters
.searchBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

// Navigation styles
.incrementDiv {
  display: flex;
  justify-content: center;
}
